export function swap(arr, a, b) {
    const temp = arr[a];
    arr[a] = arr[b];
    arr[b] = temp;
    return arr;
}

export function insert(arr, ele, pos) {
    arr.splice(pos, 0, ele);
}

export function move(arr, from, to) {
    const direction = to > from ? 1 : -1;
    const delta = Math.abs(to - from);
    for (let i = 0; i < delta; i++) {
        swap(arr, from + (i * direction), from + (i * direction) + direction);
    }
}

export function moveGroup(arr, groupSize, from, to) {
    for (let i = 0; i < groupSize; i++) {
        if (from > to) {
            move(arr, from + i, to + i);
        } else {
            move(arr, from, to);
        }
    }
}

export function deleteAt(arr, idx) {
    arr.splice(idx, 1);
}