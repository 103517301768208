import React from 'react';
import PropTypes from 'prop-types';
import roles from '../../shared/roles';
import http from '../../http/accounts';
import './RoleEditor.css';
import { Grid, Checkbox, Icon, Segment, Header } from 'semantic-ui-react';

class RoleEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            roles: props.roles
        };
    }

    static propTypes = {
        /** ID of the user to update */
        userID: PropTypes.string.isRequired,
        /** CurrentRoles the users has */
        roles: PropTypes.array.isRequired
    };

    updateRole = async (id, shouldAdd) => {
        const { userID } = this.props;
        const currentRoles = [...this.state.roles];
        const add = [];
        const remove = [];

        if (shouldAdd) {
            add.push(id);
            
            if (id === 'assistance') {
                if (currentRoles.includes('bookkeeper')) {
                    remove.push('bookkeeper');
                }
                
                if (!currentRoles.includes('advanced-user')) {
                    add.push('advanced-user');
                }
            }
            
            if (id === 'bookkeeper' && currentRoles.includes('assistance')) {
                remove.push('assistance');
            }
        } else {
            remove.push(id);
            

            if (id === 'advanced-user' && currentRoles.includes('assistance')) {
                add.push('advanced-user');
            }
        }

        try {
            const { roles } = await http.updateRoles(userID, { add, remove });
            this.props.onChange && this.props.onChange(roles);
            this.setState({
                error: null,
                roles: roles
            });
        } catch (e) {
            this.setState({
                error: e
            });
        }
    };

    roleChangeHandler = role => {
        return (_, { checked }) => {
            this.updateRole(role, checked);
        };
    };

    renderSpecialRoleColumn = (role, hasAssistance, hasBookkeeper) => {
        const { roles } = this.state;
        const { name, id, icon, color, immutable } = role;
        const roleIsActive = roles.includes(id);
        const iconColor = roleIsActive ? color : 'grey';
        const textColor = roleIsActive ? 'black' : 'grey';
        const hasAdvancedUser = roles.includes("advanced-user");
        
        return (
            <Grid.Column textAlign='center' key={id}>
                <Segment textAlign='center'>
                    <Icon name={icon} color={iconColor} size='large' />
                    <Header style={{margin:"5px", padding:"0"}} color={textColor} size='small'>{name}</Header>
                    <Checkbox
                        disabled={immutable}
                        defaultChecked={roleIsActive}
                        onChange={this.roleChangeHandler(id)}
                        toggle
                    />
                    
                    {roleIsActive && (
                        <Grid padded style={{ marginTop: '10px' }}>
                            <Grid.Row columns={2}>
                                <Grid.Column textAlign='center'>
                                    <Icon name='clipboard' size='large' color={hasBookkeeper ? 'blue' : 'grey'} />
                                    <div style={{ fontSize: '0.8em', marginBottom: '5px' }}>Rapport pakken</div>
                                    <Checkbox
                                        disabled={immutable || hasAssistance}
                                        defaultChecked={hasBookkeeper}
                                        onChange={this.roleChangeHandler("bookkeeper")}
                                        toggle
                                    />
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <Icon name='paragraph' size='large' color={hasAssistance ? 'blue' : 'grey'} />
                                    <div style={{ fontSize: '0.8em', marginBottom: '5px' }}>Erklæring pakken</div>
                                    <Checkbox
                                        disabled={immutable || hasBookkeeper}
                                        defaultChecked={hasAssistance}
                                        onChange={this.roleChangeHandler("assistance")}
                                        toggle
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column textAlign='center'>
                                    <Icon name='wrench' size='large' color={hasAdvancedUser ? 'purple' : 'grey'} />
                                    <div style={{ fontSize: '0.8em', marginBottom: '5px' }}>Advanceret bruger</div>
                                    <Checkbox
                                        defaultChecked={hasAdvancedUser}
                                        onChange={this.roleChangeHandler("advanced-user")}
                                        toggle
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}
                </Segment>
            </Grid.Column>
        );
    };

    renderRoleColumn = role => {
        const { roles } = this.state;
        const { name, id, icon, color, immutable } = role;
        const roleIsActive = roles.includes(id);
        const iconColor = roleIsActive ? color : 'grey';
        const textColor = roleIsActive ? 'black' : 'grey';
        
        const hasAssistance = roles.includes("assistance");
        const isAdvancedUserRole = id === 'advanced-user';
        const isDisabled = immutable || (isAdvancedUserRole && hasAssistance);
    
        if (id === 'assistance' || id === 'bookkeeper' || id === 'advanced-user') {
            return null;
        }
    
        if (name === 'Revisor') {
            const hasAssistance = roles.includes("assistance");
            const hasBookkeeper = roles.includes("bookkeeper");
            
            return (
                this.renderSpecialRoleColumn(role, hasAssistance, hasBookkeeper)
            );
        }
    
        return (
            <Grid.Column textAlign='center' key={id}>
                <Segment textAlign='center'>
                    <Icon name={icon} color={iconColor} size='large' />
                    <Header color={textColor} size='small'>{name}</Header>
                    <Checkbox
                        disabled={isDisabled}
                        defaultChecked={roleIsActive}
                        onChange={this.roleChangeHandler(id)}
                        toggle
                    />
                </Segment>
            </Grid.Column>
        );
    };

    render () {
        return (
            <Grid columns={3} padded>
                { roles.map(this.renderRoleColumn).filter(Boolean).sort((a, b) => a.key.localeCompare(b.key)) }
            </Grid>
        );
    }
}

export default RoleEditor;