import uuid from 'uuid/v4';
import React, { useState } from 'react';
import { Button, Icon, Input, Table } from 'semantic-ui-react';
import * as credits from 'http/credits';
import { swap } from 'shared/array';
import { confirm } from 'shared/globalModal';


const PriceStepsEditor = ({ customPriceSteps, onChange, user }) => {
    const [validationError, setValidationError] = useState(null);

    const addStep = () => onChange([...customPriceSteps, {
        id: uuid(),
        minimumCreditsRequired: customPriceSteps.length === 0 ? 0 : null,
        unitPrice: null,
    }]);

    const deleteStep = idx => onChange(customPriceSteps.filter((_, i) => i !== idx));

    const mutateStep = (idx, mutator = {}) => onChange(customPriceSteps.map((step, i) => {
        if (i === idx) {
            return { ...step, ...mutator };
        }

        return step;
    }));

    const loadDefaultPriceSteps = async () => {
        let defaultSteps;

        if (user.roles.indexOf("assistance") > -1) {
            defaultSteps = await credits.getAccountantPriceSteps();
        } else {
            defaultSteps = await credits.getDefaultPriceSteps();
        }
        onChange(defaultSteps.map(step => {
            return { ...step, id: uuid() };
        }));
    };

    const validatePriceSteps = () => {
        for (let i = 1; i < customPriceSteps.length; i++) {
            const currentStep = customPriceSteps[i];
            const lastStep = customPriceSteps[i - 1];

            if (currentStep.minimumCreditsRequired !== null && lastStep.minimumCreditsRequired >= currentStep.minimumCreditsRequired) {
                return setValidationError({
                    stepIndices: [i, i - 1],
                    message: 'Antal skal være stigende',
                });
            }

            if (currentStep.unitPrice !== null && lastStep.unitPrice <= currentStep.unitPrice) {
                return setValidationError({
                    stepIndices: [i, i - 1],
                    message: 'Stykpris skal være faldende',
                });
            }
        }

        setValidationError(null);
    };

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Minimum antal</Table.HeaderCell>
                    <Table.HeaderCell>Stykpris</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {customPriceSteps.map(({ id, minimumCreditsRequired, unitPrice }, idx) => {
                    const canGoDown = idx !== customPriceSteps.length - 1;
                    const canGoUp = idx !== 0;
                    return (
                        <Table.Row key={id} error={validationError?.stepIndices?.includes(idx)}>
                            <Table.Cell>
                                <Input
                                    type='number'
                                    placeholder='Indtast påkrævet antal'
                                    label='stk.'
                                    labelPosition='right'
                                    defaultValue={minimumCreditsRequired}
                                    onChange={(_, { value }) => mutateStep(idx, { minimumCreditsRequired: Number(value) })}
                                    onBlur={validatePriceSteps}
                                />
                            </Table.Cell>
                            <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    type='number'
                                    placeholder='Indtast stykpris'
                                    label='kr.'
                                    labelPosition='right'
                                    defaultValue={unitPrice}
                                    style={{ flex: 1 }} 
                                    onChange={(_, { value }) => mutateStep(idx, { unitPrice: Number(value) })}
                                    onBlur={validatePriceSteps}
                                />
                                <Icon
                                    icon
                                    link={canGoDown}
                                    name='chevron down'
                                    style={{ marginLeft: '1em' }}
                                    onClick={() => {
                                        onChange(swap([...customPriceSteps], idx, idx + 1));
                                    }}
                                    disabled={!canGoDown}
                                />
                                <Icon
                                    icon
                                    link={canGoUp}
                                    name='chevron up'
                                    onClick={() => {
                                        onChange(swap([...customPriceSteps], idx, idx - 1));
                                    }}
                                    disabled={!canGoUp}
                                />
                                <Icon
                                    icon
                                    link
                                    name='trash'
                                    onClick={() => confirm('Er du sikker?').then(shouldDelete => {
                                        shouldDelete && deleteStep(idx);
                                    })}
                                />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan={2}>
                        <Button
                            fluid
                            icon='plus'
                            content='Nyt trin'
                            onClick={() => addStep()}
                        />
                        {customPriceSteps.length === 0 && (
                            <Button
                                fluid
                                icon='download'
                                content='Indlæs standard pristrappe'
                                onClick={loadDefaultPriceSteps}
                                style={{ marginTop: '0.5em' }}
                            />
                        )}
                        {validationError && (
                            <span>
                                <Icon name='info circle' /> {validationError.message}
                            </span>
                        )}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

export default PriceStepsEditor;