import React, { useState, useEffect, useCallback } from 'react';
import { Segment, Header, Button } from 'semantic-ui-react';
import { getCompanyRelations } from 'http/cvr';

const RenderOwnerShipTree = ({ user }) => {

    const [ownerShip, setOwnerShip] = useState({});
    const daughterCompanies = ownerShip.daughterCompanies || [];
    const legalOwners = ownerShip.owners || [];
    const realOwners = ownerShip.realOwner || [];
      
    const fetchRelations = useCallback(async () => {
        const relations = await getCompanyRelations(user.cvr);
        setOwnerShip(relations);
    }, [user.cvr]);

    useEffect(() => {
        fetchRelations();
    }, [user, fetchRelations]);
   
    let ownersText = `Virksomheden har ikke reelle ejere, og ledelsen er indsat som reelle ejere.`;
  
    const renderOwnersList = (owners, title) => (
      <>
        <Segment>
          <div style={{ display:'flex', justifyContent: 'space-between', alignItems:'center', margin:'0' }}>
            <Header size='medium'>{title}</Header>
          </div>
          {owners.length < 1 ? (
            <Segment fluid>
              <Header size='medium'>{ownersText}</Header>
            </Segment>
          ) : (
            owners.map((owner, index) => (
              <Segment fluid key={index}>
                <Header>{owner.name}</Header>
                <Header.Subheader>
                  <span className='date'>Ejer {owner.ownershipFraction * 100}% af <b style={{ color: 'black' }}>{user.companyName}</b></span>
                </Header.Subheader>
              </Segment>
            ))
          )}
        </Segment>
      </>
    );
  
    const renderCompanyList = (companies, title) => (
      <>
        <Segment>
          <div style={{ display:'flex', justifyContent: 'space-between', alignItems:'center' }}>
            <Header style={{ margin:'0.5rem 0' }} size='medium' textAlign='left'>{title}</Header>
          </div>
          {companies.map((company, index) => (
              <Segment key={index}>
                <Header size='medium'>
                  {company.name}
                  <Header.Subheader>
                    Cvr: <span style={{ fontWeight: 'bolder' }}>{company.CVR || 'N/A'}</span>
                  </Header.Subheader>
                </Header>
              </Segment>
          ))}
        </Segment>
      </>
    );
  
    return (
      <Segment basic>
        <Button onClick={fetchRelations} primary>
          Opdater relationer
        </Button>
        {renderOwnersList(realOwners, 'Reelle Ejere')}
        {renderCompanyList(legalOwners, 'Legale Ejere')}
        {renderCompanyList(daughterCompanies, 'Datter Selskaber')}
      </Segment>
    );
};

export default RenderOwnerShipTree;