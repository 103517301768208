import { Client } from './client';
import { API_LOCATION } from '../config';
import { makeQueryParams } from 'shared/queryParams';

const client = new Client(`${API_LOCATION}/credits/admin`);

export const getCreditsBox = (uid) => {
    return client.getResource(`/users/${uid}/credits-box`);
};

export const patchCreditsBox = (uid, propertiesToPatch = {}) => {
    return client.patchResource(`/users/${uid}/credits-box`, propertiesToPatch);
};

export const addToCreditBalance = (uid, creditsToAdd, givenForFree, newExpirationDate, transactionComment = '') => {
    return client.postResource(`/users/${uid}/credits-box/add-balance`, {
        creditsToAdd,
        givenForFree,
        newExpirationDate,
        transactionComment: transactionComment.trim(),
    });
};

export const getCreditsBalance = uid => {
    return client.getResource(`/users/${uid}/credits-box/balance`);
};

export const getCreditTransactionEvents = (uid, pageIndex = 0, pageSize = 10) => {
    const query = makeQueryParams({
        pageIndex,
        pageSize,
    });

    return client.getResource(`/users/${uid}/transaction-events${query}`);
};

export const countCreditTransactionEvents = uid => {
    return client.getResource(`/users/${uid}/transaction-events/count`);
};

export const getTaxYearPurchaseTransactions = uid => {
    return client.getResource(`/users/${uid}/transaction-events/tax-year-purchases`);
};

export const getDefaultPriceSteps = () => {
    return client.getResource('/price-steps/default');
};
export const getAccountantPriceSteps = () => {
    return client.getResource('/price-steps/accountant');
};
